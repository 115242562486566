<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Emite som ao bipar produto:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza estoque minimo e ideal:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza controle de lote de validade:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Realiza baixa no estoque na criação de kit:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-divider horizontal />
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Desmembrar produto ao importar XML:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Permite incluir produto via importação do XML:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {


    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>